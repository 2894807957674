import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import LayoutWithImage from "../components/layout/LayoutWithImage"

const OeffentlicherVerkehrPage = () => (
  <Layout>
    <SEO title="Öffentlicher Verkehr" />

    <div className="pt-5 header">
      <h1>Öffentlicher Verkehr</h1>
      <h2>Abklärungen zu einem offiziellen liechtensteinischen Behindertenausweis und Merkblatt zum öffentlichen Verkehr.</h2>
    </div>

    <LayoutWithImage>
      <p>
        In Liechtenstein gibt es keinen offiziellen Behindertenausweis. Der von
        der IV ausgestellte Ausweis hat keine gesetzliche Grundlage. In
        Liechtenstein wird er aber akzeptiert.
      </p>
      <p>
        &laquo;sichtwechsel&raquo; bekam einen Auftrag von der Regierung. Es sollte
        abgeklärt werden, was für Massnahmen es braucht, um einen
        liechtensteinischen Behindertenausweis zu schaffen.
      </p>
      <p>
        Wichtig wäre, dass ein solcher Ausweis vor allem im Ausland anerkannt
        wird. Und ob man mit dem Ausweis Vergünstigungen im öffentlichen Verkehr
        bekommt. &laquo;sichtwechsel&raquo; hat bei der SBB und der ÖBB angefragt.
      </p>
      <p>
        Leider würde ein liechtensteinischer Ausweis nicht anerkannt werden.
      </p>
      <p>
        Ein Grund dafür ist, dass die Definition von dem Begriff Behinderung in
        jedem Land anders ist.
      </p>
      <p>
        Und auch die Leistungen für Menschen mit einer Behinderung sind in jedem
        Land anders.
      </p>
      <p>
        Wenn man aber eine Begleitperson benötigt, kann man diese kostenlos
        mitnehmen.
      </p>
      <p>
        Alle Informationen stehen auf <a href="https://www.llv.li/files/asd/merkblatt-ov-april-20.pdf" target="_blank">diesem Merkblatt</a>.</p>
      <p>Das <a href="https://www.llv.li/files/scg/attestformular-d-2012.pdf" target="blank">SBB-Attestformular</a> berechtigt zur kostenlosen Mitnahme einer Begleitperson.
      </p>

      <p>
        Wenn Sie Fragen haben, können Sie sich beim Amt für Soziale Dienste,
        Fachbereich Chancengleichheit (<span className="text-nowrap">Tel. 00423 236 60 60</span> oder E-Mail <a href="mailto:info.cg@llv.li">info.cg@llv.li</a>) melden.
      </p>
    </LayoutWithImage>

  </Layout>
)

export default OeffentlicherVerkehrPage
