import * as React from "react"
import { bigImages, randomBigImages } from "../../helpers/imageHelpers"
import { shuffleArray } from "../../utils/shuffleArray";
export default class LayoutWithImage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const image = shuffleArray(bigImages).map(x=>x.toString());
        
        return (
            <div className="d-flex">
                <div className="w-50 d-none d-xl-block">
                    {this.props.children}
                </div>
                <div className="d-xl-none">
                    {this.props.children}
                </div>
                <div className="w-50 d-none d-xl-block">
                    <img src={image[0]} className="img-fluid pl-4" />
                    <p className="img-label">Fotos vom Videodreh 2020 für die Trailer im Skino</p>
                </div>
            </div>
        )
    }
}
